import { SetStateAction, useState } from 'react'
import { Auth } from '../../firebase/firebase'
import { Alert, Button, Input } from '@mui/material'

export default function ForgotPage({ setForgotPass }: { setForgotPass: (value: SetStateAction<boolean>) => void }) {
    const defaultSettings = {
        sending: false,
        email: '',
        succMessage: '',
        failMessage: '',
    }
    const [emailProps, setEmailProps] = useState<{
        sending: boolean
        email: string
        succMessage: string
        failMessage: string
    }>(defaultSettings)

    const click = async () => {
        setEmailProps({ ...emailProps, sending: true })

        await Auth.sendPasswordResetEmail(emailProps.email)
            .then(() => {
                setEmailProps({
                    ...emailProps,
                    sending: false,
                    failMessage: '',
                    succMessage: 'Glemt-passord e-post sendt!',
                })
                setTimeout(() => {
                    setForgotPass(false)
                }, 1000)
            })
            .catch((err) => {
                setEmailProps({ ...emailProps, sending: false, succMessage: '', failMessage: (err as Error).message })
            })
    }

    return (
        <div className="block-center wd-xxl mt-3">
            <div className="card">
                <div className="card-header text-center bg-dark">
                    <img
                        className="block-center rounded img-fluid px-5 py-3"
                        src="img/logo.png"
                        alt="Styreportalens logo"
                    />
                </div>
                <div className="card-body">
                    {emailProps.succMessage && (
                        <Alert className="mb-2" color="success" onClose={() => setEmailProps(defaultSettings)}>
                            {emailProps.succMessage}
                        </Alert>
                    )}

                    {emailProps.failMessage && (
                        <Alert className="mb-2" color="error" onClose={() => setEmailProps(defaultSettings)}>
                            {emailProps.failMessage}
                        </Alert>
                    )}

                    <p className="text-center text-uppercase font-weight-bold">Glemt passord</p>

                    <div className="form-group mt-4 mb-4">
                        <Input
                            className="input-group"
                            placeholder="E-postadresse"
                            type="email"
                            autoFocus={true}
                            disabled={emailProps.sending}
                            value={emailProps.email}
                            onChange={({ target: { value } }) => setEmailProps({ ...emailProps, email: value })}
                        />
                    </div>

                    <Button
                        className="btn-block"
                        disabled={!emailProps.email || emailProps.sending}
                        color="primary"
                        variant="contained"
                        onClick={click}
                    >
                        Få nytt passord
                    </Button>

                    <Button color="success" className="mt-2" onClick={() => setForgotPass(false)}>
                        Gå til innlogging
                    </Button>
                </div>
            </div>
        </div>
    )
}
