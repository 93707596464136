import { useState, useEffect } from 'react'
import { firebase } from '@styreportalen/common'
import { Button, Input, InputLabel } from '@mui/material'
import DialCodePicker from '../../../reusable/DialCodePicker'

type LoginProps = {
    loggingIn: boolean
    setLoggingIn: React.Dispatch<React.SetStateAction<boolean>>
    error: (code: string | number) => void
}

export default function MobileLogin({ loggingIn, setLoggingIn, error }: LoginProps) {
    const [sendOTP, setSendOTP] = useState(false)

    const [isButtonDisabled, setIsButtonDisabled] = useState(true)

    const [selectedCountry, setSelectedCountry] = useState<{
        label: string
        value: string
    }>({
        label: 'NO',
        value: '+47',
    })

    const [phoneNumber, setPhoneNumber] = useState('')

    const [code, setCode] = useState('')

    useEffect(() => {
        ;(window as any).recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            size: 'normal',
            callback: () => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                // ...
                setIsButtonDisabled(false)
            },
            'expired-callback': () => {
                // Response expired. Ask user to solve reCAPTCHA again.
                // ...
                setIsButtonDisabled(true)
            },
        })
        ;(window as any).recaptchaVerifier.render().then(function (widgetId: any) {
            ;(window as any).recaptchaWidgetId = widgetId
        })
    }, [])

    const signInWithPhoneNumber = () => {
        error('')
        const appVerifier = (window as any).recaptchaVerifier

        setLoggingIn(true)
        firebase
            .auth()
            .signInWithPhoneNumber(selectedCountry.value + phoneNumber, appVerifier)
            .then((confirmationResult) => {
                setSendOTP(true)
                ;(window as any).confirmationResult = confirmationResult
                setLoggingIn(false)
            })
            .catch((err) => {
                error(err.code)
                setLoggingIn(false)
            })
    }

    const confirmCode = () => {
        setLoggingIn(true)
        ;(window as any).confirmationResult
            .confirm(code)
            .then(() => {
                setLoggingIn(false)
            })
            .catch((err: { code: string }) => {
                error(err.code)
                setLoggingIn(false)
            })
    }

    if (!sendOTP)
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div className="mt-2 mb-2">
                    <div className="form-group">
                        <InputLabel>Landskode</InputLabel>
                        <DialCodePicker
                            default_dialCode={'+47'}
                            selectedCountry={selectedCountry}
                            setSelectedCountry={setSelectedCountry}
                        />
                    </div>

                    <div className="form-group">
                        <Input
                            className="input-group"
                            type="tel"
                            disabled={loggingIn}
                            value={phoneNumber}
                            onChange={({ target: { value } }) =>
                                setPhoneNumber(value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))
                            }
                            onKeyPress={(key) => key.charCode === 13 && signInWithPhoneNumber()}
                            placeholder="Telefonnummer"
                        />
                    </div>
                </div>

                <div id="recaptcha-container" className="mb-3 mx-auto" />

                <Button
                    disabled={loggingIn || isButtonDisabled}
                    variant="contained"
                    color="primary"
                    onClick={signInWithPhoneNumber}
                >
                    Få verifiseringskode!
                </Button>
            </div>
        )
    else
        return (
            <div>
                <div className="form-group">
                    <Input
                        type="text"
                        className="input-group"
                        placeholder="Skriv inn den 6-siffrede koden du har mottatt på SMS"
                        value={code}
                        autoFocus={true}
                        onChange={({ target: { value } }) => setCode(value)}
                        onKeyPress={(key) => key.charCode === 13 && confirmCode()}
                    />
                </div>

                <Button
                    className="btn-block"
                    disabled={loggingIn || !code}
                    variant="contained"
                    color="primary"
                    onClick={confirmCode}
                >
                    Bekreft koden!
                </Button>
            </div>
        )
}
