import React, { lazy, Suspense } from 'react'
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import Base from './Layout/Base'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import PageLoader from './reusable/loader/PageLoader'

/* Used to render a lazy component with react-router */
export const waitFor = (Tag: any) => (props: any) => <Tag {...props} />

// Component that will be rendered in place of the component that fails to be loaded
const LoadingFailed: React.FC = () => {
    return (
        <div className="content-wrapper">
            <div className="bg-danger p-3 rounded d-flex align-items-center" style={{ gap: '.5rem' }}>
                <p className="m-0 p-0">
                    Noe gikk galt under lastingen av siden. Det betyr at vi har oppdatert systemet! Vi anbefaler å laste
                    siden på nytt.
                </p>
                <button className="btn btn-light text-dark" onClick={() => window.location.reload()}>
                    Last på nytt
                </button>
            </div>
        </div>
    )
}

const retry = (
    fn: () => Promise<{ default: React.ComponentType<any> }>
): Promise<{ default: React.ComponentType<any> }> => {
    return new Promise((resolve) => {
        fn()
            .then(resolve)
            .catch(() =>
                resolve({
                    default: LoadingFailed,
                })
            )
    })
}

// SELECT PERSON
const SelectedPerson = lazy(() => retry(() => import('./components/select-page/SelectUserPerson')))

// PERSONALIZED-PAGE (STARTPAGE)
const PersonalizedPage = lazy(() => retry(() => import('./components/startpage/PersonPage')))

const Routes: React.FC<RouteComponentProps> = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/'
    const timeout = { enter: 500, exit: 500 }

    const selectPath = 'select-person'

    return (
        <Base mainPage={currentKey !== selectPath}>
            <TransitionGroup>
                <CSSTransition key={currentKey} timeout={timeout} classNames={'rag-fadeIn'} exit={false}>
                    <Suspense fallback={<PageLoader />}>
                        <Switch>
                            <Route exact path={`/${selectPath}`} component={waitFor(SelectedPerson)} />
                            <Route exact path="/:orgId/:personId" component={waitFor(PersonalizedPage)} />
                            <Route exact path="/">
                                <Redirect to={`/${selectPath}`} />
                            </Route>
                        </Switch>
                    </Suspense>
                </CSSTransition>
            </TransitionGroup>
        </Base>
    )
}

export default withRouter(Routes)
