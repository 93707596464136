import React, { useState } from 'react'
import { Button, Input } from '@mui/material'
import { Auth } from '../../firebase/firebase'

type EmailProps = {
    loggingIn: boolean
    setLoggingIn: React.Dispatch<React.SetStateAction<boolean>>
    error: (code: string | number) => void
}

const EmailLogin = ({ loggingIn, setLoggingIn, error }: EmailProps) => {
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    const login = async () => {
        try {
            setLoggingIn(true)
            if (email === 'tokenlogin') {
                await Auth.signInWithCustomToken(password)
            } else {
                await Auth.signInWithEmailAndPassword(email, password)
            }
        } catch (e) {
            const err = e as { code: string | number }
            error(err.code)
        }

        setLoggingIn(false)
    }

    return (
        <div>
            <div className="mt-2 mb-2">
                <div className="form-group">
                    <Input
                        className="input-group"
                        type="text"
                        required
                        placeholder="E-postadresse"
                        disabled={loggingIn}
                        value={email}
                        onChange={({ target: { value } }) => setEmail(value)}
                    />
                </div>
                <div className="form-group">
                    <Input
                        className="input-group"
                        type="password"
                        required
                        placeholder="Passord"
                        disabled={loggingIn}
                        value={password}
                        onChange={({ target: { value } }) => setPassword(value)}
                    />
                </div>
            </div>

            <Button className="btn-block" color="primary" variant="contained" disabled={loggingIn} onClick={login}>
                Logg inn
            </Button>
        </div>
    )
}

export default EmailLogin
