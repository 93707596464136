import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { Auth } from '../services/firebase/firebase'

const Header: React.FC = () => {
    const click = () => {
        Auth.signOut().then(() => {
            window.location.reload()
        })
    }
    return (
        <header className="topnavbar-wrapper">
            <nav className="navbar topnavbar">
                <div className="navbar-header">
                    <Link to="/" className="navbar-brand">
                        <div className="brand-logo">
                            <img className="img-fluid" src="img/logo.png" alt="App Logo" />
                        </div>
                        <div className="brand-logo-collapsed">
                            <img
                                className="img-fluid"
                                src="img/logo-single.png"
                                alt="App Logo"
                                style={{
                                    maxHeight: '30px',
                                }}
                            />
                        </div>
                    </Link>
                </div>
                <div className="d-flex align-items-center">
                    <img
                        className="rounded-circle thumb48"
                        src={
                            'https://firebasestorage.googleapis.com/v0/b/styreportalen-drift.appspot.com/o/Div%2FNMF%2Fdemoikon_nmf.png?alt=media&token=b7686443-67a7-417e-9246-592a465c68c7'
                        }
                        alt=""
                    />
                    <Button color="primary" className="rounded-0" onClick={() => click()}>
                        <em className="icon-logout" />
                    </Button>
                </div>
            </nav>
        </header>
    )
}

export default Header
