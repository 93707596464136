import React from 'react'
import Footer from './Footer'
import Header from './Header'

const Base: React.FC<{ mainPage: boolean; children: React.ReactNode }> = ({ mainPage, children }) => {
    return (
        <div className="wrapper">
            <Header />
            <section className="section-container">{children}</section>
            {mainPage && <Footer />}
        </div>
    )
}

export default Base
