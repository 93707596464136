import { GlobalUserDetails, firebase } from '@styreportalen/common'
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { WithDocumentReferences } from '../reusable/types/wrappers'
import { Auth, DB, Functions } from '../services/firebase/firebase'
import LoadingScreen from '../reusable/loader/LoadingScreen'
import FullLogoLoader from '../reusable/loader/FullLogoLoader'
import Login from '../services/auth/Login'

type PersonsOrgs = {
    pid: string
    oid: string
}

export const UserContext = createContext<WithDocumentReferences<GlobalUserDetails> | null>(null)
export const AuthUserContext = createContext<firebase.User | null>(null)
export const PersonOrgsContext = createContext<PersonsOrgs[] | null>(null)

export default function UserContextProvider({ children }: { children: React.ReactNode }) {
    const [authUser, setAuthUser] = useState<firebase.User | null | undefined>()
    const [user, setUser] = useState<WithDocumentReferences<GlobalUserDetails> | undefined>()
    const [personOrgs, setPersonOrgs] = useState<PersonsOrgs[] | null>()
    const hasFetched = useRef<string[]>([])

    useEffect(() => {
        const cancel = Auth.onAuthStateChanged((authUser) => {
            setAuthUser(authUser)
        })

        return cancel
    }, [])

    useEffect(() => {
        if (!authUser) {
            return
        }

        DB.collection('users')
            .doc(authUser.uid)
            .get()
            .then((snapshot) => {
                setUser({
                    ...(snapshot.data() as GlobalUserDetails),
                    documentId: snapshot.id,
                    ref: snapshot.ref,
                    querySnap: snapshot,
                })
            })
    }, [authUser])

    useEffect(() => {
        if (!authUser) {
            return
        }

        DB.collection('users')
            .doc(authUser.uid)
            .onSnapshot((docSS) => {
                if (!docSS.exists) {
                    setPersonOrgs(null)
                }

                setPersonOrgs(
                    ((docSS.data() as GlobalUserDetails)?.persons_orgs || []).map((data) => ({
                        pid: data.pid,
                        oid: data.oid,
                    }))
                )
            })
    }, [authUser, user])

    useEffect(() => {
        if (authUser && !hasFetched.current.includes(authUser.uid)) {
            hasFetched.current.push(authUser.uid)
            Functions.httpsCallable('updateLastLogin')()
        }
    }, [authUser])

    if (authUser === undefined) {
        return (
            <LoadingScreen>
                <FullLogoLoader />
            </LoadingScreen>
        )
    }

    if (authUser === null) {
        return <Login />
    }

    if (user === undefined) {
        return (
            <LoadingScreen>
                <FullLogoLoader />
            </LoadingScreen>
        )
    }

    if (personOrgs === undefined) {
        return (
            <LoadingScreen>
                <FullLogoLoader />
            </LoadingScreen>
        )
    }

    return (
        <AuthUserContext.Provider value={authUser}>
            <UserContext.Provider value={user}>
                <PersonOrgsContext.Provider value={personOrgs}>{children}</PersonOrgsContext.Provider>
            </UserContext.Provider>
        </AuthUserContext.Provider>
    )
}

export const useUser = () => {
    const user = useContext(UserContext)

    if (!user) {
        throw new Error('No user available')
    }

    return user
}

export const useAuthUser = () => {
    const authUser = useContext(AuthUserContext)

    if (!authUser) {
        throw new Error('No authUser available')
    }

    return authUser
}

export const useCurrentPersonOrgs = () => {
    const personOrgs = useContext(PersonOrgsContext)

    return useMemo(() => personOrgs, [personOrgs])
}

export const useCurrentPersonIds = () => {
    const personOrgs = useContext(PersonOrgsContext)

    return useMemo(() => personOrgs?.map((person) => person.pid), [personOrgs])
}
