import ReactDOM from 'react-dom'
import App from './App'

import moment from 'moment'
import 'moment/locale/nb'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import UserContextProvider from './context/UserContext'

library.add(fas)

moment.locale('nb')

ReactDOM.render(
    <UserContextProvider>
        <App />
    </UserContextProvider>,
    document.getElementById('app')
)
