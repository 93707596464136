import { Alert, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import EmailLogin from './pages/EmailLogin'
import MobileLogin from './pages/MobileLogin'
import ForgotPage from './pages/ForgotPage'

const SwitchButton = ({
    children,
    onClick,
}: {
    children: React.ReactNode
    onClick: React.MouseEventHandler<HTMLButtonElement>
}) => (
    <Button color="primary" className="mt-2 text-left" onClick={onClick}>
        {children}
    </Button>
)

const Login = () => {
    const [emailLogin, setEmailLogin] = useState<boolean>(false)

    const [loggingIn, setLoggingIn] = useState<boolean>(false)

    const [forgotPass, setForgotPass] = useState<boolean>(false)

    const [errorMessage, setErrorMessage] = useState<string>('')

    const reset = () => {
        setErrorMessage('')
    }

    useEffect(() => {
        if (window.location.pathname !== '/') {
            window.history.replaceState(null, '', '/')
        }
    }, [])

    const error = (code: string | number) => {
        if (code === 'auth/user-not-found') setErrorMessage('Ingen konto funnet!')
        else if (code === 'auth/wrong-password') setErrorMessage('Feil passord!')
        else if (code === 'auth/invalid-phone-number')
            setErrorMessage('En feil har oppstått. Vennligst sjekk telefonnummeret, og prøv igjen.')
        else if (code === 'auth/invalid-email') setErrorMessage('E-posten er ikke formatert riktig.')
        else if (code === 400) setErrorMessage('Du har forsøkt litt for mange ganger! Vent litt, og prøv igjen.')
        else if (code === '') setErrorMessage('')
        else setErrorMessage('En ukjent feil har oppstått.')
    }

    if (forgotPass) {
        return <ForgotPage setForgotPass={setForgotPass} />
    }

    return (
        <div className="block-center wd-xxl mt-3">
            <div className="card">
                <div className="card-header text-center bg-dark">
                    <img
                        className="block-center rounded img-fluid px-5 py-3"
                        src="img/logo.png"
                        alt="Styreportalens logo"
                    />
                </div>
                <div className="card-body">
                    <p className="text-center text-uppercase font-weight-bold">Logg inn for å fortsette</p>

                    {!!errorMessage && (
                        <Alert color="error" onClose={() => setErrorMessage('')}>
                            {errorMessage}
                        </Alert>
                    )}

                    {emailLogin ? (
                        <EmailLogin loggingIn={loggingIn} setLoggingIn={setLoggingIn} error={error} />
                    ) : (
                        <MobileLogin loggingIn={loggingIn} setLoggingIn={setLoggingIn} error={error} />
                    )}

                    {emailLogin ? (
                        <SwitchButton
                            onClick={() => {
                                setEmailLogin(false)
                                reset()
                            }}
                        >
                            Logg inn med mobilnummer
                        </SwitchButton>
                    ) : (
                        <SwitchButton
                            onClick={() => {
                                setEmailLogin(true)
                                reset()
                            }}
                        >
                            Logg inn med e-post
                        </SwitchButton>
                    )}

                    <Button
                        color="error"
                        className="mt-2"
                        onClick={() => {
                            setForgotPass(true)
                            reset()
                        }}
                    >
                        Glemt passord
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Login
