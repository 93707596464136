import React, { useMemo } from 'react'
import { useUser } from '../context/UserContext'

const Footer: React.FC = () => {
    const year = useMemo(() => new Date().getFullYear(), [])

    const user = useUser()

    return (
        <footer className="footer-container">
            <span>&copy; {year} - Styreportalen AS</span>
            <span style={{ float: 'right' }}>{user.email}</span>
        </footer>
    )
}

export default Footer
